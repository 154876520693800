import { Component, OnInit } from '@angular/core';
import { sliderData } from 'src/app/models';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  data = sliderData;

  constructor() {}

  ngOnInit(): void {}
}
