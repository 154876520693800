<app-banner [title]="title"></app-banner>

<section class="ftco-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 ftco-animate">
        <div
          class="cases-wrap d-md-flex align-items-center"
          *ngFor="let item of data; let i = index"
        >
          <div
            class="img"
            [style.backgroundImage]="
              'url(assets/images/image_' + (i + 1) + '.jpg)'
            "
          ></div>
          <div class="text pl-md-5">
            <h2>{{ item.title }}</h2>
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
