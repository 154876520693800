<footer class="footer">
  <div class="container-fluid px-lg-5">
    <div class="row">
      <div class="col-md-9 py-5">
        <div class="row">
          <div class="col-md-4 mb-md-0 mb-4">
            <h2 class="footer-heading">About us</h2>
            <p>
              MK Accountify complete fundamental accounting tasks for small and
              medium-sized companies.
            </p>
          </div>
          <div class="col-md-8">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-10">
                <div class="row">
                  <div class="col-md-4 mb-md-0 mb-4">
                    <h2 class="footer-heading">Services</h2>
                    <ul class="list-unstyled">
                      <li>
                        <a href="#" class="py-1 d-block">Bookkeeping</a>
                      </li>
                      <li>
                        <a href="#" class="py-1 d-block">GST</a>
                      </li>
                      <li>
                        <a href="#" class="py-1 d-block">TDS</a>
                      </li>
                      <li>
                        <a href="#" class="py-1 d-block">Income Tax</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4 mb-md-0 mb-4">
                    <h2 class="footer-heading">Technologies</h2>
                    <ul class="list-unstyled">
                      <li>
                        <a href="#" class="py-1 d-block">Tally ERP 9/Prime</a>
                      </li>
                      <li>
                        <a href="#" class="py-1 d-block">Busy Win</a>
                      </li>
                      <li>
                        <a href="#" class="py-1 d-block">MS Excel</a>
                      </li>
                      <li><a href="#" class="py-1 d-block">CompuOffice</a></li>
                    </ul>
                  </div>
                  <div class="col-md-4 mb-md-0 mb-4">
                    <h2 class="footer-heading">Useful Links</h2>
                    <ul class="list-unstyled">
                      <li><a href="#" class="py-1 d-block">Security</a></li>
                      <li><a href="#" class="py-1 d-block">Global</a></li>
                      <li><a href="#" class="py-1 d-block">Charts</a></li>
                      <li><a href="#" class="py-1 d-block">Privacy</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-md-5">
          <div class="col-md-12">
            <p class="copyright">
              Powered
              <i class="fa fa-heart" aria-hidden="true"></i> by
              <a href="http://motogeniestechsolutions.com/" target="_blank"
                >Motogenies Tech Solutions</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3 py-md-5 py-4 aside-stretch-right pl-lg-5">
        <h2 class="footer-heading">For consultation</h2>
        <form
          class="form-consultation"
          [formGroup]="contactForm"
          (ngSubmit)="onSubmit()"
          autocomplete="off"
        >
          <div class="form-group">
            <input
              type="text"
              formControlName="name"
              placeholder="Full Name"
              class="form-control"
              [ngClass]="{
                'is-invalid': submitted && f.name.errors
              }"
            />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Full Name is required</div>
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              formControlName="mobile"
              placeholder="Mobile No"
              class="form-control"
              [ngClass]="{
                'is-invalid': submitted && f.mobile.errors
              }"
            />
            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
              <div *ngIf="f.mobile.errors.required">Mobile no is required</div>
              <div *ngIf="f.mobile.errors.pattern">
                Please, Enter 10 digit Mobile No.
              </div>
            </div>
          </div>
          <div class="form-group">
            <input
              type="text"
              formControlName="email"
              placeholder="Email Address"
              class="form-control"
              [ngClass]="{
                'is-invalid': submitted && f.email.errors
              }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">
                Email Address is required
              </div>
              <div *ngIf="f.email.errors.email">
                Email must be a valid email address
              </div>
            </div>
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              formControlName="message"
              cols="30"
              rows="4"
              placeholder="Message"
              [ngClass]="{
                'is-invalid': submitted && f.message.errors
              }"
            ></textarea>
            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
              <div *ngIf="f.message.errors.required">Message is required</div>
            </div>
          </div>
          <h2 class="footer-heading">{{ responseMessage }}</h2>
          <div class="form-group">
            <button type="submit" class="form-control submit px-3">
              Send A Message
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</footer>
<app-spinner></app-spinner>
