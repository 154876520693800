<div class="wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="bg-wrap">
          <div class="row">
            <div class="col-md-6 d-flex align-items-center">
              <p class="mb-0 phone pl-md-2">
                <a href="#" class="mr-2"
                  ><span class="fa fa-phone mr-1"></span> {{ phoneNumber }}</a
                >
                <a href="#"
                  ><span class="fa fa-paper-plane mr-1"></span> {{ emailId }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<nav
  class="
    navbar navbar-expand-lg navbar-dark
    ftco_navbar
    bg-dark
    ftco-navbar-light
  "
  id="ftco-navbar"
>
  <div class="container">
    <a class="navbar-brand" routerLink="/home">{{ title }}</a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#ftco-nav"
      aria-controls="ftco-nav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="fa fa-bars"></span> Menu
    </button>
    <div class="collapse navbar-collapse" id="ftco-nav">
      <ul class="navbar-nav m-auto">
        <li
          class="nav-item"
          routerLinkActive="active"
          *ngFor="let item of links"
        >
          <a routerLink="{{ item.link }}" class="nav-link">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
