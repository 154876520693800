import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  responseMessage: string;

  constructor(private fb: FormBuilder, private contactService: ContactService) {
    this.contactForm = this.fb.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      mobile: [
        null,
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      message: [null, Validators.required],
      honeypot: '',
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (
      this.contactForm.invalid &&
      this.contactForm.controls.honeypot.value == ''
    ) {
      return;
    } else {
      this.contactService.sendMessage(this.contactForm).subscribe(
        (response) => {
          this.responseMessage = this.contactService.getResponseMessage(
            response['result']
          );
          this.clearForm();
        },
        (error) => {
          this.responseMessage = this.contactService.getResponseMessage(
            error['statusText']
          );
          this.clearForm();
        }
      );
    }
  }

  clearForm() {
    this.contactForm.reset();
    Object.keys(this.contactForm.controls).forEach((key) => {
      this.contactForm.get(key).setErrors(null);
    });
    setTimeout(() => {
      console.log('hide');
      this.responseMessage = '';
    }, 5000);
  }
}
